<template>
  <div>
    <div class="main" v-title data-title="百川资管-我们是谁">
    </div>
    <Header class="header" active="about" />
    <div class="banner">
      <div class="background">
      </div>
    </div>
    <div class="content">
      <section class="superiority">
        <b-container>
          <b-row>
            <div class="text">公司简介</div>
            <div>
              <p>百川财富（北京）投资管理有限公司（以下简称“百川资管”）成立于2015年，2016年获中国证券投资基金业协会私募基金管理人资格，私募基金备案号为：P1020003。</p>
              <p>百川资管是一家致力于为机构和高端个人投资者实现全球化资产配置的私募基金资产管理机构。公司核心团队由券商、公募基金和海外资管公司核心投资成员共同发起组成，公司致力于成为一家业内领先的基本面量化投资基金公司。</p>
            </div>
          </b-row>
          <b-row>
            <div class="text" style="margin-top:30px">我们的优势</div>
          </b-row>
          <div class="list">
            <div class="item row background-team">
              <div class="col-3 title">完美的团队构成</div>
              <div class="col-9 pl50">
                <div>团队由基本面投研团队和量化投研团队共同构成。主观基本面深度研究帮助我们坚守价值投资的理念，找到优质的价值能长期成长的公司，确保长期盈利；量化分析帮助我们进行全市场扫描，理解市场客观运行规律，做到敬畏市场，规避风险。人机结合(优劣/共振)</div>
                <div class="pt20">团队在境内和境外有多年的投资经验，具有丰富的全球视野和投资经验，在日趋国际化的中国股市尤据优势</div>
              </div>
            </div>
            <div class="item row background-strategy">
              <div class="col-9 pr100">
                <div>投资组合可以根据基本面量化策略，在不同市场状况下在不同策略、不同品种以及不同市场间进行灵活配置，团队在A股、港股和美股等多个市场具有丰富的全球配置的投资经验</div>
              </div>
              <div class="col-3 title">灵活的投资策略</div>
            </div>
          </div>
        </b-container>
      </section>
      <section class="idea">
        <b-container>
          <b-row>
            <div class="text">
              投资理念：拥抱高质量成长股
            </div>
          </b-row>
          <div class="wd86">
            <div class="col-6">
              <div class="background">
                <div class="title">我们的基本面研究</div>
                <div class="sub-title">体系化深入的研究企业的经营，以长期战略股东的角度判断企业的投资价值与企业共同成长</div>
              </div>
            </div>
            <div class="col-6">
              <div class="background">
                <div class="title">我们的量化研究</div>
                <div class="sub-title">客观呈现市场运行轨迹，敬畏市场，顺势而为，运用模型，但不迷信模型</div>
              </div>
            </div>
          </div>
          <div class="model">
            <img src="../assets/superiority_1.jpg">
          </div>
        </b-container>
      </section>
      <section class="strategy">
        <b-container>
          <div class="text">
            核心策略：基本面精选+量化配置=双轮驱动
          </div>
          <div class="wd86">
            <div class="col-6">
              <div class="item">
                <img src="../assets/product7.png">
                <div class="title">基本面精选</div>
              </div>
            </div>
            <div class="col-6">
              <div>
                <img src="../assets/product6.png">
                <div class="title">量化配置</div>
              </div>
            </div>
          </div>
          <div class="wd86">
            <div class="col-6 br">
              <div class="item">
                <div class="sub-title">通过深入的基本面分析，精选业绩优秀、价值能长期成长的行业龙头公司，形成股票池，全球视角覆盖A股、港股、美股</div>
              </div>
            </div>
            <div class="col-6">
              <div>
                <div class="sub-title">基于自有的宏观、行业景气度等基本面量化配置体系，结合投资者行为、资金流动、动量趋势等市场面量化指标，灵活进行配置和风险管理</div>
              </div>
            </div>
          </div>
        </b-container>
      </section>
      <section class="team">
        <b-container>
          <b-row>
            <div class="text">核心投资管理团队</div>
          </b-row>
          <b-row style="justify-content:center">
            <div class="col-3 m-3">
              <div class="background1">
                <div class="desc3">
                  吴秋如，美国罗格斯大学会计硕士，曾任华泰香港研究助理，复星恒利证券研究员， 覆盖A股和港股市场医药公司，良好的财会背景夯实数据信息的分析能力，多年研究经验深化对二级市场认识，寻求行业的长期投资逻辑性。
                </div>
              </div>
            </div>
            <div class="col-3 m-3">
              <div class="background1">
                <div class="desc4">
                  唐琪，硕士研究生，曾任宁波宽信资产量化研究员，长城证券量化投资部研究员，深圳长乐汇资本研究员。系统化的价值投资的坚定信仰者。主张投资由量化主导，基本面降维，量化与基本面深度融合的打法，遵循将投资常识系统化、流程化、原则化的思路。
                </div>
              </div>
            </div>
          </b-row>
        </b-container>
      </section>
    </div>
    <Footer class="footer" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Product',
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
.banner {
  height: 550px;
}
.banner .background {
  width: 100%;
  height: 100%;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/about1.jpg");
}
.superiority {
  padding: 50px 0;
}
.superiority .text {
  width: 100%;
  text-align: center;
  font-size: 32px;
  color: #333333;
  padding-bottom: 20px;
}
.superiority .list .item {
  padding: 30px 20px;
  height: 200px;
  margin: 20px 0 0 0;
}
.superiority .list .title {
  font-size: 24px;
  line-height: 140px;
}
.background-team {
  width: 100%;
  height: 100%;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/about2.png");
}
.background-strategy {
  width: 100%;
  height: 100%;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/about3.png");
}
.pt20 {
  padding-top: 20px;
}
.pl50 {
  padding-left: 50px;
}
.pr100 {
  padding-right: 100px;
}
.pr100 div {
  position: relative;
  top: 50%;
  margin-top: -1.5rem;
}

.idea .text {
  color: #333333;
  font-size: 26px;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 60px;
}
.idea .background {
  width: 80%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/product2.png");
  padding: 10px;
  border-radius: 5px;
}
.idea .background .title {
  font-size: 18px;
  border-bottom: solid 1px #ffffff;
  color: #ffffff;
  text-align: center;
  padding-bottom: 5px;
}
.idea .background .sub-title {
  padding-top: 5px;
  color: #ffffff;
  font-size: 14px;
  line-height: 1.8;
}
.wd86 {
  width: 86%;
  margin: 0 auto;
  display: flex;
}
.wd86 .br {
  border-right: solid 1px #333333;
}
.wd66 {
  width: 66%;
  margin: 0 auto;
}

.idea .model {
  padding: 50px 0;
  text-align: center;
}
.idea .model img {
  width: 80%;
  margin: 0 auto;
}

.strategy {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../assets/product4.png");
  margin-top: -90px;
}
.strategy .container {
  position: relative;
  top: 150px;
  margin-bottom: 200px;
}
.strategy .title {
  padding: 20px 0;
  font-size: 18px;
  color: #0078d9;
}

.strategy .text {
  color: #333333;
  font-size: 26px;
  text-align: center;
  width: 100%;
  padding-bottom: 40px;
}

.strategy .col-6 {
  text-align: center;
  padding: 0 5%;
}
.strategy img {
  width: 120px;
}

.team .text {
  width: 100%;
  text-align: center;
  font-size: 32px;
  color: #333333;
  padding: 20px 0 40px 0;
}

.team .col-3 {
  padding: 0;
  height: 260px;
}
.team .background1 {
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 8px;
}
.team .desc1 {
  padding: 15px;
  position: relative;
  font-size: 14px;
  line-height: 1.8;
  height: 260px;
  border-radius: 5px;
  background-color: #258ce0;
}
.team .desc2 {
  padding: 15px;
  position: relative;
  font-size: 14px;
  line-height: 1.8;
  height: 260px;
  border-radius: 5px;
  background-color: #297cd1;
}
.team .desc3 {
  padding: 15px;
  position: relative;
  font-size: 14px;
  line-height: 1.8;
  height: 260px;
  border-radius: 5px;
  background-color: #306daf;
}
.team .desc4 {
  padding: 15px;
  position: relative;
  font-size: 14px;
  line-height: 1.8;
  height: 260px;
  border-radius: 5px;
  background-color: #4e7aaa;
}

.team {
  padding-bottom: 50px;
}
</style>
